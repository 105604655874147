import './Engagements.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Helpers from '../../../../utils/helper';
import { useStateSelector } from '../../../../store/selectors';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import createNotification from '../../../../utils/createNotification';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { TimelineEvent } from '../../../../slices/marketplace/models/timeline-event.model';
import { TimelineTemplate } from '../../Model/TimelineTemplate';

const Engagements = () => {
    const dispatch = useDispatch() as any;

    const timelineStages = useStateSelector(
        (state) => state.timelines.timelineStages
    );

    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);

    const navigate = useNavigate();
    const axios = useStateSelector((state) => state.core.axios);

    const [isLoading, setIsLoading] = useState(false);

    const defaultSorted: [{ dataField: any; order: any }] = [
        {
            dataField: 'name',
            order: 'asc',
        },
    ];
    const [currentSort, setCurrentSort] = useState<TableFilter>(
        new TableFilter({ filters: [], logic: '' }, page, sizePerPage, {
            dir: 'asc',
            field: 'id',
        })
    );

    const handleTableChange = (_type: any, newState: any) => {
        const { page, sizePerPage, sortField, sortOrder } = newState;

        const updatedPostObj = new TableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            { dir: sortOrder, field: sortField }
        );
        setCurrentSort(updatedPostObj);

        //fetchEngagements();
    };

    const engagementsColumns = [
        {
            dataField: 'id',
            text: 'Id',
            headerClasses: 'name-column-header',
            sort: true,
        },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: string) =>
                !cellContent ? '-' : cellContent,
        },
        {
            dataField: 'ownerRepresentative',
            text: 'Owner Representative',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: string) =>
                !cellContent ? '-' : cellContent,
        },
        {
            dataField: 'status',
            text: 'Status',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: string) =>
                !cellContent ? '-' : cellContent,
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: string) =>
                !cellContent ? '-' : cellContent,
        },
        {
            dataField: 'currentEvent',
            text: 'Current Stage / Event',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: TimelineEvent) =>
                !cellContent
                    ? 'NA'
                    : `${
                          timelineStages.find((s) => s.id == cellContent.stage)
                              ?.name ?? 'NA'
                      } / ${cellContent.name}`,
        },
        {
            dataField: 'deadline',
            text: 'Deadline',
            headerClasses: 'last-edited-column-header',
            sort: true,
            formatter: (cellContent: string) => (
                <div>
                    {cellContent
                        ? moment(cellContent).format('M/D/YYYY h:mmA')
                        : '-'}
                </div>
            ),
        },
        {
            dataField: '',
            text: 'Actions',
            headerClasses: 'actions-column-header',
            headerFormatter: () => (
                <div className="d-flex justify-content-between w-100">
                    <div className="w-100">Actions</div>
                </div>
            ),
            style: {
                minWidth: 350,
            },
            formatter: (_cellContent: number, row: TimelineTemplate) => {
                return (
                    <>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3"
                            onClick={() => {}}>
                            Seller Dashboard
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3"
                            onClick={() => {}}>
                            Timeline
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3"
                            onClick={() => {}}>
                            Fee Schedule
                        </Button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="engagements">
                <div>
                    <div className="mb-3">
                        <strong>Engagements</strong>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3 right-btn"
                            onClick={() => {}}>
                            Define New Engagement
                        </Button>
                    </div>
                    <div
                        role="tabpanel"
                        id="panel-0"
                        aria-labelledby="tab-0"
                        aria-hidden="false">
                        <div className="card-body">
                            {isLoading ? (
                                Helpers.renderTableLoader()
                            ) : (
                                <BootstrapTable
                                    striped
                                    bordered
                                    bootstrap4
                                    remote
                                    keyField="id"
                                    data={[]}
                                    columns={engagementsColumns}
                                    defaultSorted={defaultSorted}
                                    filter={filterFactory()}
                                    pagination={paginationFactory({
                                        page,
                                        sizePerPage,
                                        totalSize,
                                        tooltipTargetId:
                                            'timeline-templates-tooltip',
                                    })}
                                    onTableChange={handleTableChange}
                                    noDataIndication="No Engagements to display"
                                    rowStyle={{ height: '44px' }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Engagements;
